
import { getQuickAddCartInstance, canUseNewQuickAdd } from 'public/src/pages/components/product/quick_add_v3/common.js'

export const getFlowMap = ({ startIndex, endIndex, flowData, flowDataV2, PageState, catInfo, cateCoupon, isRankingSlotVisible }) => {
  const { flowRank, flowDiscountChannel, flowStoreFollowing = {}, flowSurveyInfo = {}, flowListBanner } = flowDataV2 || {}
  const skeletonLoading = PageState?.skeletonLoading || false
  const isSearch = catInfo?.type === 'search'
  const isStore = catInfo?.type === 'store'
  const { isSpaPage, hasHandleFilterBar } = PageState || {}

  // ! 广告流
  const cccxContext = flowData?.cccxFlowAd?.context || flowListBanner?.context

  // ! 列表榜单商品条件
  const ListComponentAbt = flowRank?.Abt?.ListComponentAbt // 

  const getCfgByIndex = (index) => {
    // 搜索券
    /*
      ！ 插坑券
      搜索券特有的条件:
      skeletonLoading结束后再展示 否则SPA重进的情况会出现曝光2次的问题
    */
    
    const searchShowCondition = isSearch ? (isSpaPage && !hasHandleFilterBar && !skeletonLoading) : true
    const curCoupon = cateCoupon.Data?.[index + 1] ?? {}
    const { showType, position } = curCoupon ?? {}
    let hasCouponList = curCoupon?.couponList?.length
    if (showType === 'AccountFlow') {
      if (!curCoupon.isPdSearchPageShow) hasCouponList = false
    }
    const flowSearchCoupon = ['Flow', 'AccountFlow'].includes(showType) && hasCouponList && position - 1 === index && searchShowCondition

    // 广告流
    const cccxFlowAd = !flowSearchCoupon && (flowData?.cccxFlowAd?.data?.[index] || flowListBanner?.data?.[index])
    const flowAd = !flowSearchCoupon && flowData?.FlowAd?.Data?.[index]

    // 榜单流
    const ListComponentData = flowRank?.Data?.flowRankingList?.deliverPlaceItemsObject?.[index]
    let flowRankingList = ListComponentData && ListComponentAbt
    if(isStore){ // 店铺 通过 isRankingSlotVisible 控制
      flowRankingList = flowRankingList && isRankingSlotVisible
    }

    // ! 类目推荐
    const flowRealCate = flowData.FlowRealCate?.Data?.[index]

    // ! 锦囊搜索
    const flowCellSearch = flowData?.FlowCellSearch?.Data?.[index]

    // ! 搜了还搜
    const flowSearch = flowData?.FlowSearch?.Data?.[index]

    // ! 买家秀
    const flowBuyerShow = flowData?.FlowBuyerShow?.Data?.[index]

    // ! 调查问卷组件
    const flowSurveyQuestion = +flowSurveyInfo?.showPosition === index + 1

    /**
     * 获取商品流组件展示状态
     * 列表优先级：领券 > 广告组件（banner > 榜单商品组件 > 真实类目推荐 > 买家秀（下架）
     * 搜索优先级：领券 > 广告组件（banner(未接) > 搜了又搜 > 搜索锦囊筛选 > 榜单商品组件 > 真实类目推荐（未放量）  > 买家秀（下架）
     * 
     * @param {string} key - 组件的key
     * @returns {boolean} - 组件是否展示的状态
     */
    const getFlowComponentShow = (key) => {
      // 商品流各个组件展示的优先级 都为true时，展示index靠前的组件
      const FLOW_SHOW_LEVEL = [
        {
          key: 'flowDiscountChannel',
          flag: flowDiscountChannel?.positionData?.[index]?.length > 0,
        },
        {
          key: 'flowSearchCoupon', // 券
          flag: flowSearchCoupon,
        },
        {
          key: 'cccxFlowAd', // cccx广告流
          flag: cccxFlowAd,
        },
        {
          key: 'flowAd', // 老ccc广告流
          flag: flowAd,
        },
        {
          key: 'flowRankingList', // 榜单
          flag: flowRankingList,
        },
        {
          key: 'flowRealCate', // 类目推荐
          flag: flowRealCate,
        },
        {
          key: 'flowBuyerShow', // 买家秀
          flag: flowBuyerShow,
        },
        {
          key: 'flowSurveyQuestion', // 调查问卷
          flag: flowSurveyQuestion,
        }
      ]
      // 搜索独有的两个流组件
      const SEARCH_FLOW_SHOW_LEVEL = [
        {
          key: 'flowSearch', // 搜了还搜
          flag: flowSearch,
        },
        {
          key: 'flowCellSearch',
          flag: flowCellSearch,
        },
      ]

      // 店铺独有流组件
      const STORE_FLOW_SHOW_LEVEL = [
        {
          key: 'flowStoreFollowing',
          flag: flowStoreFollowing.listSlot?.some?.(item => item - 1 === index),
        }
      ]

      isStore && FLOW_SHOW_LEVEL.push(...STORE_FLOW_SHOW_LEVEL)

      // 如果是搜索 将相关搜索/锦囊搜索插入到FLOW_SHOW_LEVEL
      isSearch && FLOW_SHOW_LEVEL.splice(2, 0, ...SEARCH_FLOW_SHOW_LEVEL)
      let current = 0
      const flowObj = FLOW_SHOW_LEVEL.find((item, index) => {
        if (item.key === key) {
          current = index
          return item
        } 
      })
  
      // 如果当前组件为true 且 上位组件均为 false 则展示当前组件
      return flowObj?.flag && FLOW_SHOW_LEVEL.slice(0, current).every(item => !item?.flag)
    }

    return {
      getFlowComponentShow,
      cccxFlowAd,
      ListComponentData,
    }
  }

  const flowMap = {}

  for (let index = startIndex; index <= endIndex; index++) {
    const results = []
   
    const { getFlowComponentShow, cccxFlowAd, ListComponentData } = getCfgByIndex(index)
    // 商品流广告
    if (getFlowComponentShow('cccxFlowAd')) {
      results.push({
        index,
        compTag: 'cccxFlowAd',
        props: {
          cccxContext,
          content: [cccxFlowAd],
        },
      })
    } else if (getFlowComponentShow('flowAd')) {
      results.push({
        index,
        compTag: 'flowAd',
        props: {
          flowData: flowData.FlowAd,
        },
      })
    }

    // 榜单商品
    if (getFlowComponentShow('flowRankingList')) {
      if ('FlowRankingListC' === ListComponentAbt) {
        results.push({
          index,
          compTag: 'FlowRankingListC',
          props: {
            flowRankingList: ListComponentData,
          },
        })
      }
    }

    // 类目推荐
    if (getFlowComponentShow('flowRealCate')) {
      results.push({
        index,
        compTag: 'flowRealCate',
        props: {
          flowData: flowData.FlowRealCate,
        },
      })
    }

    // 搜了还搜/相关搜索
    if (getFlowComponentShow('flowSearch')) {
      results.push({
        index,
        compTag: 'flowSearch',
        props: {
          flowData: flowData.FlowSearch,
        },
      })
    }

    // 锦囊搜索
    if (getFlowComponentShow('flowCellSearch')) {
      results.push({
        index,
        compTag: 'flowCellSearch',
        props: {
          flowData: flowData.FlowCellSearch,
        },
      })
    }

    // 折扣频道内容体插坑
    if (getFlowComponentShow('flowDiscountChannel')) {
      results.push({
        index,
        compTag: 'flowDiscountChannel',
        props: {
          products: flowDiscountChannel?.positionData?.[index] || [],
          discountParams: flowDiscountChannel?.discountParams || {},
        },
      })
    }
    
    // 商品流买家秀
    if (getFlowComponentShow('flowBuyerShow')) {
      results.push({
        index,
        compTag: 'flowBuyerShow',
        props: {
          flowData: flowData.FlowBuyerShow,
        },
      })
    }

    if (getFlowComponentShow('flowSearchCoupon')) {
      results.push({
        index,
        compTag: 'flowSearchCoupon',
      })
    }

    // 调查问卷
    if (getFlowComponentShow('flowSurveyQuestion')) {
      results.push({
        index,
        compTag: 'flowSurveyQuestion',
        props: {
          surveyInfo: flowSurveyInfo,
        },
      })
    }

    if (getFlowComponentShow('flowStoreFollowing')) {
      results.push({
        index,
        compTag: 'FlowItemTabFollow',
      })
    }

    if (results.length) {
      flowMap[index] = {
        isFlowData: true,
        results,
      }
    }
  }

  return flowMap
}

export const getFlowRankingListMixin = () => ({
  inject: {
    FLOW_RANK_STATE_TOKEN: {
      default: () => ({}),
    },
  },
  data () {
    return {
      clickMode: 'A',
    }
  },
  computed: {
    flowRankAnalysis() {
      return this.FLOW_RANK_STATE_TOKEN.flowRankAnalysis || {}
    },
    rankAbtInfo() {
      return this.FLOW_RANK_STATE_TOKEN.rankAbtInfo || {}
    },
    rankSaData() {
      return this.flowRankAnalysis.rankSaData
    },
    contentCarrierId() {
      return this.flowRankingList?.contentCarrierId || ''
    },
    getActivityFrom() {
      return `ranking_list_${this.carrierData?.carrierSubType}`
    },
    // 外部自定义的埋点数据
    customData() {
      return this.flowRankingList?.customData || {}
    }
  },
  created () {
    if (this.customData.from !== 'storeItem' && this.productItemConfig) {
      this.productItemConfig.disableMainimgJump = true
    }
  },
  methods: {
    handleClickItem (e, item) {
      if (this.customData.from === 'storeItem') { // 店铺场景跳商详
        this.handleToGoodDetail && this.handleToGoodDetail(item)
        return
      }
      this.flowRankAnalysis.triggerClickGoods({ item })
      this.handleClickRankingList()
    },
    async handleClickRankingList() {
      const { isNewPage, link: rankingLink } = await this.rankingLink()
      if (rankingLink) {
        if (isNewPage) {
          window.location.href = rankingLink
        } else {
          this.$router.push(rankingLink)
        }
      }
    },
    // 打开快速加车
    async handleOpenQuickAdd({ item, index, target }) {
      const { goods_id, mall_code, goods_img } = item

      if (canUseNewQuickAdd('list')) {
        getQuickAddCartInstance().open({
          goods_id,
          mallCode: mall_code,
        }, {
          featureConfig: {
            showEstimatedPrice: true,
          },
          extendedParam: {
            mainImg: goods_img,
          },
          analysisConfig: {
            code: 'productList',
            sourceTarget: target || null,
            sa: {
              activity_from: this.activityFrom,
              ranking_from: this.analyticsRankingFrom,
              passParams: {
                ranking_from: this.analyticsRankingFrom,
                src_identifier: this.srcIdentifier,
                src_module: this.srcModule,
              },
            },
          },
        })
        return
      }

      await this.$quickAdd.open({
        index,
        goods_id,
        lockmall: !!this.catInfo.lockMall,
        mallCode: mall_code,
        mainImg: goods_img,
        showBestDealLabel: true,
        showEstimatedPrice: true,
        analysisConfig: {
          code: 'productList',
          sourceTarget: target || null,
          sa: {
            activity_from: this.rankSaData.src_module,
            ranking_from: this.rankSaData.ranking_from,
            passParams: {
              ranking_from: this.rankSaData.ranking_from,
              src_identifier: this.rankSaData.src_identifier,
              src_module: this.rankSaData.src_module,
            },
          },
        },
      })
    },
  },
})
