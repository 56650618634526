export function pageStateFactory(patch) {
  return {
    ready: false,
    lcp: true,
    landingLock: true,
    pageleave: false,
    noMoreData: false,
    hasFilt: false,
    hasHandleFilterBar: false, // 是否已经点击过筛选栏
    exposeCode: '',
    staticAttrSetId: '', // 前端保存当前筛选属性id
    staticFilterType: 'attr', // 前端保存当前筛选属性类型: attr/tsp
    infiniteScrollDistance: typeof window !== 'undefined'
      ? Math.floor(window.innerWidth * 0.75 * 5) // 滚动预加载距离
      : 10000,
    infiniteScrollLock: false,
    // 用于判断当前页面是否是 SPA 渲染出来的页面
    isSpaPage: false,
    spuImgMap: {},
    searchPageLoading: false, // 当是搜索结果页，直达词判断前需要显示全屏loading，后显示骨架屏
    skeletonLoading: false, // 骨架屏loading态
    searchOptimizeReqFlag: false, // 搜索页翻页优化- 请求🔒标记
    ...patch,
  }
}

export function componentStateFactory(patch) {
  return {
    searchCCCCard: null,
    BrandBanner: null,
    ListTopInfo: null,
    ProductList: {
      config: {},
      FlowData: {
        FlowRealCate: null,
      },
      flowDataV2: {
        flowRank: null, // 榜单插坑
        flowDiscountChannel: null,
        flowSurveyInfo: null,
        flowListBanner: null, // 广告流插坑
      },
      // 流式组件
      flowMap: {},
      // 列表券
      cateCoupon: {
        Data: {},
        Abt: {}
      },
      // 券用到的多语言
      couponLanguage: {},

      goodsItemInfoServ: null,
      analysisData: null,
      // 用于判断是否是渲染的第一个商品
      isFirstItem: false,
      waterfallRenderDirectionLTR: false, // 控制瀑布流是否强制从左到右渲染。
    },
    FilterBar: null,
    NoResult: {},
    SearchFeedback: null,
    SearchRelated: null,
    ListBottomInfo: null,
    queryParams: {},
    productListDeleteFlag: false, // 商品列表删除标记
    ...patch,
  }
}

export function resultsFactory(patch) {
  return {
    listAbtResult: {},
    cccSeo: {},
    cccOperation: {},
    mallInfo: [],
    cccConfig: {
      mobileVerticalView: {},
    },
    sheinClubInfo: {},
    promotionInfoFromServer: {},
    atomicParams: {},
    cat_info: {},
    currentCat: {},
    parentCats: {},
    searchKeywords: {
      double_lang_correct: '',
    },
    searchBannerCard: null,
    sum: 0,
    sumForPage: 0,

    goods: [],
    appendGoods: [],

    // 实时曝光的商品
    exposedGoods: [],
    exposedGoodsWithSpu: [],
    // 缓存当前页面中的所有点推商品
    exposeDynamicRecommendGood: [],

    dailyDates: {},
    filterCates: [],
    filterPrices: {},
    picTopNav: {},
    picTopNavCate: [],

    filterAttrs: [],
    filterTsps: [],
    cloudTags: [],
    bannerTag: {},
    kidsTag: undefined,

    tracking: {},
    request_ext: {},
    goodsCrowId: {},
    fhContext: {},
    googleContext: {},

    filterAbtParam: {
      abt: {},
    },
    // 福利政策
    policyBanner: {},

    // 折扣落地页
    discountPageInfo: {},
    // apollo配置
    apolloConfig: {},
    ...patch,

    // 商卡配置
    cardConfig: {},

    // 中间层过滤的商品Id
    frontFilterGoods: '',
  }
}

export function stateFactory() {
  return {
    Locals: {},
    Language: {},
    PageState: pageStateFactory(),
    ComponentState: componentStateFactory(),
    Results: resultsFactory(),
    GoodsItemInfoServ: null,
    ageLimitDialogShow: '', // 是否弹了成人弹框，用于控制层级 ''/true/false; ''-未知，true-展示, false-不展示
    oneClickPayGuideDialogShow: '', // 是否弹了一键购新人指引弹框，用于控制层级 ''/true/false
  }
}

export default stateFactory()
